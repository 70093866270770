import React, { useState, useEffect, useCallback, useRef } from 'react';
import ExifReader from 'exifreader'; // Import the library
import heic2any from 'heic2any';
import ImageBlobReduce from 'image-blob-reduce';
import Pica from 'pica'
import { logoBase64 } from './logo';
import SignInButton from './SignInButton';
import CameraDetailsCard from './CameraDetailsCard';
import UploadStatusList from './UploadStatusList';
import NoDateDialog from './NoDateDialog';
import submitIcon from './images/submit.svg';
import submitIconDisabled from './images/submit-disabled.svg';
import './App.css';
import { formatDate, generatePhotoPeriod } from './utils/dateHelpers';
import { auth, signInWithCustomToken, logoutUser, uploadImageWithDetails, readEpisode, getSubmissionsByUID, getStartDate, getUserInfo, readInstant, checkAndIncrementEvent } from './firebase';

const pica = Pica({ features: ["js", "wasm", "cib"] });

function App() {
  const [user, setUser] = useState({});
  const [authMethod, setAuthMethod] = useState(null); // Track the method of authentication
  const [cameraDetails, setCameraDetails] = useState({});
  const [uploadStatus, setUploadStatus] = useState({ completed: 0, message: '', errors: [], inProgress: false });
  const [isInvalidEvent, setIsInvalidEvent] = useState(false);
  const [noUploadsAllowed, setNoUploadsAllowed] = useState(false);
  const [showNoDateDialog, setShowNoDateDialog] = useState(false);
  const [noDatePhotoCount, setNoDatePhotoCount] = useState(0);
  const [redirectValue, setRedirectValue] = useState('');
  const [redirectError, setRedirectError] = useState('');

  const [version, setVersion] = useState(null);
  const [loading, setLoading] = useState(true);

  const userRef = useRef(user);
  const authMethodRef = useRef(authMethod);
  const uploadDataRef = useRef(null);

  const reducer = new ImageBlobReduce({pica});

  const fileInputRef = useRef(null);
  const messagesRef = useRef(null);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    authMethodRef.current = authMethod;
  }, [authMethod]);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    // Get the encoded timestamps string from the URL
    const encodedTimestamps = urlParams.get('timestamps');

    if (!encodedTimestamps) {
      return;
    }

    let timestamps = [];

    // Attempt to parse the JSON string to get the array of timestamps
    try {
      timestamps = JSON.parse(decodeURIComponent(encodedTimestamps));
    } catch (error) {
      console.error('Failed to parse timestamps:', error);
    }

    const allowAnyDateUploadsString = urlParams.get('allowAnyDateUploads');
    const allowAnyDateUploads = allowAnyDateUploadsString === 'true';

    setUser({
      uid: urlParams.get('uid'),
      name: urlParams.get('fullName'),
    });

    setCameraDetails({
      name: urlParams.get('name'),
      startDate: urlParams.get('startDate'),
      endDate: urlParams.get('endDate'),
      formattedStartDate: urlParams.get('formattedStartDate'),
      formattedEndDate: urlParams.get('formattedEndDate'),
      allowAnyDateUploads: allowAnyDateUploads,
      photosPerPerson: urlParams.get('photosPerPerson'),
      shotsRemaining: urlParams.get('shotsRemaining'),
      photoPeriod: urlParams.get('photoPeriod'),
      episodeID: urlParams.get('episodeID'),
      nodeID: urlParams.get('nodeID'),
      creator: urlParams.get('creator'),
      episodeTitle: urlParams.get('episodeTitle'),
      timestamps
    });
  }, []);

  // Make sure fetchData is properly defined to handle loading of necessary data based on user details
  const fetchData = useCallback(async () => {
    const eventID = new URLSearchParams(window.location.search).get('event')?.split('?')[0];
    const instantID = new URLSearchParams(window.location.search).get('instant')?.split('?')[0];
    
    if (!eventID && !instantID) {
      setIsInvalidEvent(true);
      return;
    }

    let uid;
    const currentUser = userRef.current;
    if (currentUser && currentUser.uid) {
      uid = currentUser.uid;
    } else {
      const storedUserInfo = localStorage.getItem('userInfo');
      if (storedUserInfo) {
        const userInfo = JSON.parse(storedUserInfo);
        uid = userInfo.uid;
        setUser(userInfo);
      }
    }

    const currentAuthMethod = authMethodRef.current;
    if (currentAuthMethod !== 'token') {
      const eventIncrementedKey = `eventIncremented_${eventID || instantID}`;
      const eventIncremented = localStorage.getItem(eventIncrementedKey);
      if (!eventIncremented && uid) {
        checkAndIncrementEvent(currentUser, uid, eventID || instantID, !eventID);
      }
    }

    let episodeData, nodeID;

    if (instantID) {
      episodeData = await readInstant(instantID);

      if (!episodeData) {
        setIsInvalidEvent(true);
        return;
      }

      if (episodeData.hasOwnProperty('cameraDetails') && episodeData.cameraDetails.hasOwnProperty('allowCameraRollUploads') && !episodeData.cameraDetails.allowCameraRollUploads) {
        setNoUploadsAllowed(true);
        return;
      }

      nodeID = "1915A747-6CCE-43D6-84AF-3BF93DDDB131";
    } else {
      episodeData = await readEpisode(eventID);

      if (!episodeData) {
        setIsInvalidEvent(true);
        return;
      }
      
      if (episodeData.hasOwnProperty('cameraDetails') && episodeData.cameraDetails.hasOwnProperty('allowCameraRollUploads') && !episodeData.cameraDetails.allowCameraRollUploads) {
        setNoUploadsAllowed(true);
        return;
      }

      const nodes = episodeData.graph?.nodes ?? [];
      for (let node of nodes) {
        if (typeof node === 'object' && node.nodeType === 'povCamera') {
            nodeID = node.id;

            if (!episodeData.cameraDetails) {
              episodeData.cameraDetails = {
                name: episodeData.title,
                endDate: {
                  seconds: node.attributes.endDate,
                },
                photosPerPerson: node.attributes.numberOfShots,
              };

            }
            break;
        }
      }

      
      if (!nodeID) {
        nodeID = "1915A747-6CCE-43D6-84AF-3BF93DDDB131"
        // console.error("Node ID for 'povCamera' not found.");
        // setIsInvalidEvent(true);
        // return;
      }
    }

    try {
      // Common processing block for both instant and event data
      const createdAt = new Date(episodeData.createdAt.seconds * 1000);
      let startDate = createdAt;

      let timestamps = [];
      let submissionsArray = [];
      let shotsRemaining = episodeData.cameraDetails.photosPerPerson;

      let endDate = episodeData.cameraDetails.endDate && !isHostFinish(episodeData.cameraDetails.endDate) ? new Date(episodeData.cameraDetails.endDate.seconds * 1000) : null;
      let formattedEndDate = endDate ? formatDate(endDate) : null;
      const allowAnyDateUploads = episodeData.cameraDetails.allowAnyDateUploads ?? false;

      const episodeID = eventID ? eventID : instantID;

      if (uid) {
        sessionStorage.setItem(`nodeID-${episodeID}`, nodeID);
        startDate = await getStartDate(episodeID, nodeID);
        const submissionsData = await getSubmissionsByUID(uid, episodeID, nodeID);

        let cameraRollCount = 0;
        if (submissionsData && Object.keys(submissionsData).length > 0) {
          submissionsArray = Object.values(submissionsData);
          timestamps = submissionsArray.map(submission => new Date(submission.timestamp).toISOString());

          cameraRollCount = submissionsArray.reduce((count, submission) => submission.cameraRollTimestamp ? count + 1 : count, 0);
        }

        shotsRemaining -= cameraRollCount;
      }

      const formattedStartDate = startDate ? formatDate(startDate) : null;
      const photoPeriod = generatePhotoPeriod(startDate, endDate, shotsRemaining, allowAnyDateUploads);
      const isHost = episodeData.creator === uid;

      setCameraDetails({
        ...formatData(eventID, nodeID, episodeData),
        timestamps,
        shotsRemaining,
        photoPeriod,
        startDate,
        formattedStartDate,
        endDate,
        formattedEndDate,
        allowAnyDateUploads,
        episodeID,
        nodeID,
        isHost
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsInvalidEvent(true);
    }
  }, []);

  function isHostFinish(endDate) {
    return isNaN(endDate.seconds);
  }

  const handleGoogleSignIn = useCallback((user) => {
    getUserInfo(user.uid).then(userInfo => {
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      setUser(userInfo);
      setAuthMethod('google');
      fetchData();
      const urlParams = new URLSearchParams(window.location.search);
      checkAndIncrementEvent(user, user.uid, urlParams.get('event') ?? urlParams.get('instant'), urlParams.get('event') == null);
    });
  }, [fetchData, setUser, setAuthMethod]);

  const signInWithToken = useCallback((token) => {
    signInWithCustomToken(auth, token)
      .then((result) => {
        return getUserInfo(result.user.uid);
      })
      .then(userInfo => {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        setUser(userInfo);
        setLoading(false);
      })
      .catch(error => {
        console.error('Authentication error with token:', error);
        setLoading(false);
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.unauthenticated) {
          window.webkit.messageHandlers.unauthenticated.postMessage('unauthenticated');
        }
      });
  }, [setUser, setLoading]);

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');

    if (!auth.currentUser && token) {
      setAuthMethod('token');
      // User is not logged in, and we have a token
      signInWithToken(token);
    }
  }, [signInWithToken]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const eventID = urlParams.get('event');
    const instantID = urlParams.get('instant');
    // This handles all scenarios: token-based, existing sessions, and no user data

    const unsubscribe = auth.onAuthStateChanged(userData => {
      if (userData) {
        const newAuthMethod = token != null ? 'token' : 'google';
        setAuthMethod(newAuthMethod);

        // Check for stored user info
        const storedUserInfo = localStorage.getItem('userInfo');
        const handleUserInfo = userInfo => {
          setUser(userInfo); // Set user state
          localStorage.setItem('userInfo', JSON.stringify(userInfo)); // Cache user info
    
          if (newAuthMethod !== 'token') {
            fetchData().finally(() => setLoading(false)); // Fetch data and then set loading to false
          } else {
            setLoading(false); // Just set loading to false
          }
        };
    
        if (!storedUserInfo) {
          // No user info in local storage, fetch from server
          getUserInfo(userData.uid).then(handleUserInfo).catch(error => {
            console.error('Failed to fetch user info:', error);
            setLoading(false);
          });
        } else {
          // Parse stored user info and process
          handleUserInfo(JSON.parse(storedUserInfo));
        }
      } else {
        console.log('no user or token!');
        // No user and no token, ensure user is null and clean up
        localStorage.removeItem('userInfo');
        setUser(null);
        setLoading(false);
        
        const newAuthMethod = token != null ? 'token': 'none';
        setAuthMethod(newAuthMethod);

        if (!token) {
          setIsInvalidEvent(!eventID && !instantID);
          fetchData();
        }
      }
    });
  
    return () => unsubscribe();
  }, [fetchData, handleGoogleSignIn, signInWithToken]);

  const formatData = (eventID, nodeID, episodeData) => {
    const { cameraDetails, createdAt } = episodeData;
    // Formatting the data for UI consumption
    const formattedData = {
      name: cameraDetails.name,
      createdAt: createdAt,
      webEndDate: episodeData.webEndDate, // Assuming 'webEndDate' is at the root of 'episodeData'
      photosPerPerson: cameraDetails.photosPerPerson,
      episodeID: eventID,
      nodeID: nodeID,
      creator: episodeData.creator,
      episodeTitle: episodeData.title
    };
  
    return formattedData;
  };

  useEffect(() => {
    function handleUpdate(message) {
      if (message.detail.type === 'cameraRollUpdated') {
        setUploadStatus({ completed: 0, total: 0, message: '', inProgress: false, errors: [] });
        setCameraDetails(prevDetails => ({
          ...prevDetails,
          shotsRemaining: message.detail.shotsRemaining,
          photoPeriod: message.detail.photoPeriod,
          timestamps: message.detail.timestamps
        }));
      }
    }
  
    window.addEventListener('cameraRollUpdated', handleUpdate);
  
    return () => {
      window.removeEventListener('cameraRollUpdated', handleUpdate);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const versionParam = parseInt(urlParams.get('version'), 10);
    setVersion(Number.isInteger(versionParam) ? versionParam : 1);
  }, []);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  
  const handleButtonClick = debounce(() => {
    const hasShotsRemaining = cameraDetails.shotsRemaining > 0;

    if (!uploadStatus.inProgress && hasShotsRemaining && fileInputRef.current) {
        fileInputRef.current.click();
    }
  }, 100);

  const handleFileChange = async (event) => {
    const files = event.target.files;
    setUploadStatus({ ...uploadStatus, errors: [], inProgress: true });

    let processedData = await processFiles(files);
    processedData.fileInput = event.target;

    if (processedData.noDatePhotos.length > 0 && processedData.excessFiles === 0 && authMethod === 'google') {
      setNoDatePhotoCount(processedData.noDatePhotos.length);
      setShowNoDateDialog(true);
      uploadDataRef.current = processedData;
    }
    else {
      continueUpload(processedData);
    }
  };

  const processFiles = async (files) => {
    let successfulPhotos = 0;
    let excessFiles = 0;
    let newTimestamps = [];
    let newErrors = [];
    let uploadQueue = [];
    let noDatePhotos = [];
    let errorData = {
      noDatePhotosCount: 0,
      shotsRemaining: cameraDetails.shotsRemaining,
      validRange: getValidRangeString()
    };
  
    const allowAnyDateUploads = cameraDetails.allowAnyDateUploads || !cameraDetails.startDate;

    let index = 0;
    let duplicatePhotos = 0;
    let photosTakenBefore = 0;
    let photosTakenAfter = 0;
    let invalidFiletypePhotos = 0;
    let screenshots = 0;

    for (const file of files) {
      index++;
      setUploadStatus({ ...uploadStatus, message: `Processing ${index} of ${files.length} photos`, inProgress: true });

      let blob = file;

      try {
        const fileType = file.type;
        let photoDate;

        if (fileType === 'image/png') {
          const pngData = await parsePNGData(file);
          const isScreenshot = detectIfScreenshots(pngData);

          if (isScreenshot) {
            screenshots++;
            continue;  // Skip screenshots
          }

          photoDate = findCreateDate(pngData);

          if (!photoDate && (cameraDetails.isHost || allowAnyDateUploads)) {
            photoDate = new Date();
          }

          if (!photoDate) {
              noDatePhotos.push(file);
              continue;  // Skip files without a valid CreateDate
          }
        }
        else if (fileType === 'image/jpeg' || fileType === 'image/heic' || fileType === 'fileType/tiff') {
          const buffer = await file.arrayBuffer();
          const tags = ExifReader.load(buffer, { expanded: true });  // Load EXIF data from the buffer
          const exifDate = getExifDate(tags);

          if (exifDate && exifDate.trim() !== "") {
            const formattedExifDate = exifDate.replace(/(\d{4}):(\d{2}):(\d{2})/, '$1-$2-$3T').replace(' ', '');
            photoDate = new Date(formattedExifDate);
          }
          else {
            if (cameraDetails.isHost || allowAnyDateUploads) {
              photoDate = file.lastModified ? new Date(file.lastModified) : new Date();
            }
            
            if (!photoDate) {
              noDatePhotos.push(file);
              continue;  // Skip files without a valid CreateDate
            }
          }
        }
        else {
          invalidFiletypePhotos++;
          continue;
        }

        const startDate = cameraDetails.startDate ? new Date(cameraDetails.startDate) : null;
        const endDate = cameraDetails.endDate ? new Date(cameraDetails.endDate) : null;

        // Check if the photo date is within the specified range
        if (cameraDetails.timestamps.includes(photoDate.toISOString())) {
          duplicatePhotos++;
        } else if (startDate && photoDate < startDate && !allowAnyDateUploads) {
            photosTakenBefore++;
        } else if (endDate && photoDate > endDate && !allowAnyDateUploads) {
            photosTakenAfter++;
        } else {
            if (fileType === 'image/heic') {
                // Convert HEIC to JPEG
                const conversionResult = await heic2any({
                    blob: file,
                    toType: "image/jpeg",
                    quality: 0.75
                });
                blob = conversionResult;
            }
            else {
              blob = await reducer.toBlob(blob, {
                max: 1200,
                quality: 0.75  // adjust quality only once here if converted
              });
            }

            const newItem = {
              file: blob,
              timestamp: photoDate.toISOString()
            };
           
            uploadQueue.push(newItem);
            successfulPhotos++;
            newTimestamps.push(photoDate.toISOString());

            if (cameraDetails.shotsRemaining - successfulPhotos <= 0) {
              excessFiles = files.length - index;
              break;
            }
        }
      } catch (error) {
        console.error('Error trying to parse: ' + error);
        noDatePhotos.push(file);
      }
    }

    errorData.noDatePhotosCount = noDatePhotos.length;

    // Generate error messages based on counts
    if (excessFiles > 0) {
      const suffix = cameraDetails.shotsRemaining > 1 ? `Select your best ${cameraDetails.shotsRemaining}!` : `You only have one more!`;
      newErrors.push(`Too many photos selected. ${suffix}`);
    }
    else {
      if (duplicatePhotos > 0) {
        const error = duplicatePhotos === 1 ? `You tried adding a photo you've already uploaded` : `You tried adding photos you've already uploaded`;
        newErrors.push(error);
      }
  
      if (screenshots > 0) {
        const error = `Screenshots aren't allowed`;
        newErrors.push(error);
      }

      const validRange = cameraDetails.startDate && cameraDetails.endDate ? `taken between ${cameraDetails.formattedStartDate} and ${cameraDetails.formattedEndDate} will be accepted` : (cameraDetails.startDate ? `taken after ${cameraDetails.formattedStartDate} will be accepted` : `will be accepted`);
  
      if (photosTakenBefore > 0) {
        const error = photosTakenBefore === 1 ? `A photo was taken before the event's start date. Uploads ${validRange}` : `${photosTakenBefore} photos were taken before ${cameraDetails.formattedStartDate}. Uploads ${validRange}`;
        newErrors.push(error);
      }
  
      if (photosTakenAfter > 0) {
        const error = photosTakenAfter === 1 ? `A photo was taken after the event's end date. Uploads ${validRange}` : `${photosTakenAfter} photos were taken after ${cameraDetails.formattedEndDate}. Uploads ${validRange}`;
        newErrors.push(error);
      }

      if (authMethod !== 'google' && noDatePhotos.length > 0) {
        const noDatesErrorString = getNoDatesErrorString(errorData);
        newErrors.push(noDatesErrorString);
      }
  
      if (invalidFiletypePhotos > 0) {
        const prefix = cameraDetails.shotsRemaining === 1 ? `1 photo` : `Up to ${cameraDetails.shotsRemaining} photos`;
        const suffix = allowAnyDateUploads ? (cameraDetails.shotsRemaining === 1 ? `Select your best photo!` : `Select your best ${cameraDetails.shotsRemaining} photos!`) : `${prefix} ${validRange}`

        const error = invalidFiletypePhotos === 1 ? `A photo isn't a .PNG, .JPG, or .HEIC. ${suffix}` : `${invalidFiletypePhotos} photos aren't a .PNG, .JPG, or .HEIC. ${suffix}`;
        newErrors.push(error);
      }
    }
  
    return {
      successfulPhotos,
      excessFiles,
      newTimestamps,
      newErrors,
      uploadQueue,
      noDatePhotos,
      errorData,
      total: files.length
    };
  };

  const handleNoDateDialogResponse = (userChoice) => {
    setShowNoDateDialog(false);

    const uploadData = uploadDataRef.current;
    
    if (userChoice === 'yes') {
      const processedNoDatePhotos = processNoDatePhotos(uploadData.noDatePhotos);
      
      uploadData.successfulPhotos += processedNoDatePhotos.successfulPhotos;
      uploadData.newTimestamps = [...uploadData.newTimestamps, ...processedNoDatePhotos.newTimestamps];
      uploadData.uploadQueue = [...uploadData.uploadQueue, ...processedNoDatePhotos.uploadQueue];
  
      continueUpload(uploadData);
    } else {
      // Reset the file input and update upload status
      const noDatesErrorString = getNoDatesErrorString(uploadData.errorData);
      uploadData.newErrors.push(noDatesErrorString);

      // Reset the file input and update upload status
      if (uploadData.fileInput) {
        uploadData.fileInput.value = null;
      }

      setUploadStatus(prevStatus => ({ 
        ...prevStatus, 
        inProgress: false, 
        completed: 0, 
        errors: uploadData.newErrors 
      }));
    }
  
    uploadDataRef.current = null;
  };

  const processNoDatePhotos = (noDatePhotos) => {
    let successfulPhotos = 0;
    let newTimestamps = [];
    let uploadQueue = [];
  
    for (const file of noDatePhotos) {
      const photoDate = file.lastModified ? new Date(file.lastModified) : new Date();
  
      const newItem = {
        file: file,
        timestamp: photoDate.toISOString()
      };
  
      uploadQueue.push(newItem);
      successfulPhotos++;
      newTimestamps.push(photoDate.toISOString());
    }
  
    return { successfulPhotos, newTimestamps, uploadQueue };
  };

  const getValidRangeString = () => {
    if (cameraDetails.startDate && cameraDetails.endDate) {
      return `taken between ${cameraDetails.formattedStartDate} and ${cameraDetails.formattedEndDate} will be accepted`;
    } else if (cameraDetails.startDate) {
      return `taken after ${cameraDetails.formattedStartDate} will be accepted`;
    } else {
      return `will be accepted`;
    }
  };

  const getNoDatesErrorString = (errorData) => {
    const { noDatePhotosCount, shotsRemaining, validRange } = errorData;
    const prefix = shotsRemaining === 1 ? `1 photo` : `Up to ${shotsRemaining} photos`;
    return noDatePhotosCount === 1 ? `A photo doesn't have a date. ${prefix} ${validRange}` : `${noDatePhotosCount} photos don't have dates. ${prefix} ${validRange}`;
  }

  const continueUpload = (uploadData) => {
    const { successfulPhotos, excessFiles, newTimestamps, newErrors, uploadQueue, total } = uploadData;
  
    if (successfulPhotos > 0 && excessFiles === 0) {
      cameraDetails.shotsRemaining -= successfulPhotos;
      cameraDetails.timestamps = [...cameraDetails.timestamps, ...newTimestamps];
      setUploadStatus({ completed: 0, message: 'Uploading photos...', inProgress: true, errors: newErrors });
      uploadAllImages(uploadQueue, newErrors, total).then(results => {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.uploadComplete) {
          window.webkit.messageHandlers.uploadComplete.postMessage(JSON.stringify({
            successfulUploads: results.successfulUploads,
            errors: results.errors
          }));
        }
      });
    } else {
      setUploadStatus(prevStatus => ({ ...prevStatus, inProgress: false, completed: 0, errors: newErrors }));
    }
  };
    
  const handleLogout = () => {
    localStorage.removeItem('userInfo');
    logoutUser().then(() => {
      setUser(null);
    });
  };

  const validateURL = (url) => {
    const urlPattern = /^(https:\/\/(www\.)?pov\.camera\/(qr|i)\/|(www\.)?pov\.camera\/(qr|i)\/)[a-zA-Z0-9-]{12,}$/;
    return urlPattern.test(url);
  };
  
  const handleRedirectChange = (event) => {
    const url = event.target.value;
    setRedirectValue(url);
  
    if (url.length > 0 && !validateURL(url)) {
      setRedirectError('Invalid POV URL');
    } else {
      setRedirectError('');
    }
  };

  const handleRedirectSubmit = () => {
    if (redirectValue === '') {
      setRedirectError('Input cannot be empty.');
      return;
    }
  
    // Clear error if validation passes
    setRedirectError('');
  
    let redirectUrl = window.location.href.split(/[/?]/)[0];
    let queryName = '';
    let queryValue = '';
  
    if (redirectValue.includes('/qr/')) {
      queryName = 'event';
      queryValue = redirectValue.split('/qr/').pop();
    } else if (redirectValue.includes('/i/')) {
      queryName = 'instant';
      queryValue = redirectValue.split('/i/').pop();
    }
  
    if (queryName && queryValue) {
      redirectUrl += `?${queryName}=${queryValue}`;
    }
    // Redirect the URL
    window.location.href = redirectUrl;
  };

  const uploadImage = async (image, index, total) => {
    try {
      const timestamp = (new Date(image.timestamp)).getTime();
      await uploadImageWithDetails(
        image.file,
        timestamp,
        user,
        cameraDetails.episodeID,
        cameraDetails.nodeID,
        cameraDetails.creator,
        cameraDetails.name
      );

      setUploadStatus(prev => ({
        ...prev,
        completed: prev.completed + 1,
        message: `${prev.completed + 1} of ${total} photos uploaded`
      }));
      return { success: true };
    } catch (error) {
      console.error(`Error uploading image ${index + 1}:`, error);
      return { success: false, error: error };
    }
  };
  
  const uploadAllImages = async (uploadQueue, validationErrors, total) => {
    const uploadPromises = uploadQueue.map((image, index) =>
        uploadImage(image, index, uploadQueue.length)
    );

    const results = await Promise.all(uploadPromises);
    const errors = results.filter(result => !result.success);
    const successfulUploads = results.length - errors.length;

    if (errors.length > 0 || validationErrors.length > 0) {
      const newErrors = [...validationErrors, ...errors.map((result, index) => `Error in image ${index + 1}: ${result.error.message}`)];
      setUploadStatus(prev => ({
          ...prev,
          message: `Uploaded ${successfulUploads} of ${total} images!`,
          errors: newErrors,
          inProgress: false
      }));
    } else {
      const message = cameraDetails.shotsRemaining === 0 ? `You've used all your camera roll shots! Tap one if you change your mind` : (uploadQueue.length === 1 ? `Successfully uploaded a photo! You have ${cameraDetails.shotsRemaining} remaining` : `Successfully uploaded photos! You have ${cameraDetails.shotsRemaining} remaining`);
        setUploadStatus(prev => ({
            ...prev,
            message: message,
            inProgress: false
        }));
    }

    return {
        successfulUploads,
        errors
    };
  };

  const parsePNGData = async (pngFile) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const buffer = e.target.result;
            const view = new DataView(buffer);
            let pos = 8;  // Start after the 8-byte PNG signature
            let metadataTexts = [];

            while (pos < buffer.byteLength) {
                const length = view.getUint32(pos);
                const type = String.fromCharCode(
                    view.getUint8(pos + 4),
                    view.getUint8(pos + 5),
                    view.getUint8(pos + 6),
                    view.getUint8(pos + 7)
                );

                if (type === 'IEND') break;

                if (type === 'tEXt' || type === 'zTXt' || type === 'iTXt') {
                    const dataStart = pos + 8;
                    const dataEnd = dataStart + length;
                    const data = new TextDecoder('utf-8').decode(new Uint8Array(buffer.slice(dataStart, dataEnd)));
                    metadataTexts.push(data);
                }

                pos += length + 12;  // Move to the next chunk
            }

            resolve(metadataTexts);
        };

        reader.onerror = (e) => reject(e);
        reader.readAsArrayBuffer(pngFile);
    });
  };

  const detectIfScreenshots = (pngData) => {
    return pngData.some(text => text.includes('Screenshot'));
  };

  const findCreateDate = (pngData) => {
    for (const xmlString of pngData) {
        if (xmlString.includes('CreateDate="')) {
            const parts = xmlString.split('CreateDate="');
            if (parts.length > 1) {
                const date = parts[1].split('"')[0];
                return new Date(date); // Return the extracted date
            }
        }
    }

    return null;
  };

  // Helper function to extract date from EXIF tags
  const getExifDate = (tags) => {
    if (tags['exif']) {
      return getExifDate(tags['exif']);
    }

    if (tags['DateTimeOriginal']) return tags['DateTimeOriginal'].description;
    if (tags['DateTime']) return tags['DateTime'].description;
    if (tags['DateTimeDigitized']) return tags['DateTimeDigitized'].description;
    if (tags['CreateDate']) return tags['CreateDate'].description;
    return null;
  };


  if (noUploadsAllowed) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-theme-bg">
        <img src={require('./images/noUploadsAllowed.png')} alt="No Uploads Allowed" className="max-w-32 pb-3" />
        <div className="font-satoshi font-medium text-lg text-theme-white-100">
          Host Disabled Camera Roll Uploads 😕
        </div>
      </div>
    );
  }

  const hasToken = new URLSearchParams(window.location.search).get('token') != null;

  if (loading && (!hasToken || !auth.currentUser)) {
    const colorName = version === 2 ? ' bg-theme-v2-clip-bg' : (hasToken ? '' : ' bg-theme-bg');
    const loadingClassName = `flex flex-col items-center justify-center min-h-screen${colorName}`
    return (
      <div className={loadingClassName}>
        <div className="font-satoshi font-medium text-theme-white-50">Loading...</div>
      </div>
    );
  }

  if (isInvalidEvent) {
    const title = authMethod === 'none' ? 'No Event Found' : 'Event Not Found';
    const isButtonDisabled = redirectValue.length === 0 || redirectError.length > 0;
    const buttonImageSrc = isButtonDisabled ? submitIconDisabled : submitIcon;
    
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-theme-bg">
      <img src={require('./images/eventNotFound.png')} alt="Event Not Found" className="max-w-16 pb-3" />
      <p className="font-satoshi font-medium text-lg text-theme-white-100 pb-1">{title}</p>
      {authMethod !== 'none' && (
        <p className="font-satoshi font-medium text-md text-theme-white-50">Try a different link</p>
      )}
      <div className="flex flex-col items-center w-full max-w-md">
      <div className="flex w-full bg-theme-white-10 rounded-[10px] bg-gradient-to-b from-theme-gradient-translucent-alt-top to-theme-gradient-translucent-alt-bottom mt-4 p-[1px] shadow">
        <div className="flex w-full bg-theme-translucent-alt rounded-[10px]">
          <input
            type="text"
            value={redirectValue}
            onChange={handleRedirectChange}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !isButtonDisabled) {
                handleRedirectSubmit();
              }
            }}
            placeholder="Enter a POV event URL"
            className="flex-grow p-3 bg-transparent text-theme-white-100 placeholder-theme-white-60 font-satoshi focus:outline-none"
          />
          <button
            onClick={handleRedirectSubmit}
            disabled={isButtonDisabled}
            className={`p-3 rounded-md ${isButtonDisabled ? '' : 'hover:opacity-50'}`}
          >
            <img src={buttonImageSrc} alt="Submit" className="w-7 h-7"/>
          </button>
        </div>
      </div>
      <div className='h-8 items-center'>
      {redirectError && <p className="text-theme-red font-satoshi font-medium mt-2">{redirectError}</p>}
      </div>
      </div>
    </div>
    );
  }

  if ((!user || user.length === 0) && authMethod !== 'token') {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-theme-bg">
        <div className="flex flex-col items-center justify-center pb-8">
          <img src={logoBase64} alt="POV Logo" className='max-w-16 pb-3'/>
          <p className='font-satoshi font-medium text-lg text-theme-white-100 pb-1'>Sign In to Upload Photos</p>
          <p className='font-satoshi font-medium text-md text-theme-white-50'>{cameraDetails.name || 'Loading event...'}</p>
        </div>
        <SignInButton onSignIn={handleGoogleSignIn} />
      </div>
    );
  }

  const translucentBg = version === 2 ? 'bg-theme-v2-translucent' : 'bg-theme-v2-translucent';
  const translucentBorder = version === 2 ? 'from-theme-v2-gradient-translucent-top to-theme-v2-gradient-translucent-bottom' : 'from-theme-v1-gradient-translucent-top to-theme-v1-gradient-translucent-bottom';
  const isActionable = cameraDetails.shotsRemaining > 0 && !uploadStatus.inProgress;
  const hasUploadedShots = cameraDetails.shotsRemaining < cameraDetails.photosPerPerson;
  const buttonColorName = isActionable ? (hasUploadedShots ? `${translucentBg} text-theme-white-100` : 'bg-theme-purple-fill text-theme-white-100') : 'bg-theme-purple-disabled text-theme-white-50';
  const activeName = isActionable ? 'active:opacity-70' : '';
  const gradientName = isActionable ? (hasUploadedShots ? translucentBorder : 'from-theme-gradient-purple-top to-theme-gradient-purple-bottom') : 'from-theme-gradient-disabled-top to-theme-gradient-disabled-bottom';

  let buttonText;

  if (uploadStatus.inProgress) {
    buttonText = "Loading..."
  }
  else if (uploadStatus.errors.length > 0) {
    buttonText = 'Try Again';
  }
  else if (cameraDetails.shotsRemaining === 0 || !hasUploadedShots) {
    buttonText = "Add Shots";
  }
  else {
    buttonText = 'Add More Shots';
  }

  const buttonClassName = `${buttonColorName} font-satoshi font-bold text-[17px] w-full h-[56px] py-3 px-4 rounded-[10px] ${activeName} select-none`;
  const outlineClassName = `rounded-[10px] bg-gradient-to-b ${gradientName} mt-4 p-[1px]`;

   // Depending on the auth method, render different components
  return (
    <div className="App">
      {authMethod === 'google' ? (
        <div>
          <CameraDetailsCard
            user={user}
            cameraDetails={cameraDetails}
            buttonText={buttonText}
            buttonClassName={buttonClassName}
            fileInputRef={fileInputRef}
            handleFileChange={handleFileChange} 
            handleButtonClick={handleButtonClick}
            handleLogout={handleLogout}
            uploadStatus={uploadStatus}
          />
          <NoDateDialog
            isOpen={showNoDateDialog}
            noDatePhotoCount={noDatePhotoCount}
            onConfirm={() => handleNoDateDialogResponse('yes')}
            onCancel={() => handleNoDateDialogResponse('no')}
          />
        </div>
      ) : (
        <div
        className={`flex items-center justify-center h-screen ${
          version === 2 ? 'bg-theme-v2-clip-bg' : 'bg-theme-v1-clip-bg'
        }`}>
        <div className='cardContainer flex flex-col w-full h-full' ref={messagesRef}>
            <UploadStatusList uploadStatus={uploadStatus}
                              cameraDetails={cameraDetails}
                              showGalleryMessage={false}
                              version={version}/>
            <input multiple type="file" accept="image/*,.heic,.heif" name="image" id="file-input" onChange={handleFileChange} ref={fileInputRef} className="hidden"/>
            <label htmlFor="file-input" className="cursor-pointer">
              <div className={outlineClassName}>
                <button type="button" 
                        className={buttonClassName}
                        onClick={handleButtonClick}>
                  {buttonText}
                </button>
              </div>
            </label>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;